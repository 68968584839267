import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/views/OidcCallback");
const AsanaCallback = () =>
  import(/* webpackChunkName: "AsanaCallback" */ "@/views/AsanaCallback");
const DisplayError = () =>
  import(/* webpackChunkName: "DisplayError" */ "@/views/DisplayError");
const Reporting = () =>
  import(/* webpackChunkName: "Reporting" */ "@/views/Reporting");

import ReportingChildren from "./children/reporting-children";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Reporting",
    component: Reporting,
    children: ReportingChildren
  },
  {
    path: "/displayerror",
    name: "DisplayError",
    component: DisplayError
  },

  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true
    }
  },
  {
    path: "/asana-callback",
    name: "AsanaCallback",
    component: AsanaCallback,
    meta: {
      isPublic: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));

export default router;
